import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "units", "unitsRate", "totalAmount" ]

  connect() {
    console.log('hello, Stimulus!', this.element)
  }

  calculate(event) {
    let total = parseInt(this.unitsTarget.value) * parseInt(this.unitsRateTarget.value)
    this.totalAmountTarget.value = total
  }
}
